
import { Component, Mixins } from 'vue-property-decorator';
import { mdiMagnify, mdiPencil, mdiPlus } from '@mdi/js';
import Page from '@/views/Page.vue';
import CourseCard from '@/components/courses/CourseCard.vue';
import { DataTableSortMixin, VuetifyMixin, DebounceMixin, LocalForageMixin } from '@/mixins';
import { CourseModel } from '@/models/course';
import { formatDatePretty } from '@/helpers/date';
import { DataTableHeader } from 'vuetify';
import { coursesStore } from '@/store';
import { RepositoryQuery, QueryOptions } from '@/../types/interfaces';

const DEFAULT_OPTIONS = {
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	sortDesc: [true],
	groupBy: [],
	groupDesc: [false],
	multiSort: false,
	mustSort: false,
};

@Component({
	components: {
		Page,
		CourseCard,
	}
})
export default class InstructorCourseList extends Mixins(DataTableSortMixin, DebounceMixin, LocalForageMixin, VuetifyMixin){
	mdiMagnify = mdiMagnify;
	mdiPencil = mdiPencil;
	mdiPlus = mdiPlus;
	formatDatePretty = formatDatePretty;

	mounted(): void{
		this.debounceUpdateTable();
	}
	localForagePersistFields: Array<string | [string, any]> = [
		['tableOptions', DEFAULT_OPTIONS], 
		['search', ""],
	];
	tableUpdatePending: boolean = false;
	debounceUpdateTable(): void{
		this.tableUpdatePending = true;
		this.debounceCallback('updateTable', async () => {
			try{
				await this.updateTable();
			}catch(e){
				console.error("Failed to update table");
			}finally{
				this.tableUpdatePending = false;
			}
		}, 400);
	}
	async updateTable(): Promise<void>{
		this.persistField(this.LocalForagePersistFieldKeys);
		return await this.loadCourses();
	}

	search: string = "";
	tableLoading: boolean = false;
	async loadCourses(): Promise<void>{
		this.tableLoading = true;
		try{
			const query: RepositoryQuery<CourseModel> = {
				search: this.search,
				fields: ['name'],
			};
			const options: QueryOptions = { 
				page: this.tableOptions.page,
				limitPerPage: this.tableOptions.itemsPerPage,
			};
			if(this.tableOptions.sortBy.length > 0){
				options.sort = {
					fields: this.tableOptions.sortBy.map((field, index) => {
						return {
							field: field,
							desc: this.tableOptions.sortDesc[index],
						};
					}),
				};
			}
			await coursesStore.queryAllCourses({ query, options });
		}catch(e){
			console.error(e);
		}finally{
			this.tableLoading = false;
		}
	}

	headers: DataTableHeader<any>[] = [
		{
			text: 'Name',
			value: 'name',
			sortable: true,
		},
		{
			text: 'Start Date',
			value: 'startDate',
			sortable: true,
		},
		{
			text: 'End Date',
			value: 'endDate',
			sortable: true,
		},
		{
			text: 'Instructors',
			value: 'Instructors',
			sortable: false,
		},
		{
			text: 'Students',
			value: 'students',
			sortable: false,
		},
		{
			text: '',
			value: 'data-table-controls'
		},
	];

	get Courses(): CourseModel[]{
		return coursesStore.allCourses;
	}
	get TotalPages(): number{
		return coursesStore.allCoursesQueryPages;
	}
	get TotalCourses(): number{
		return coursesStore.allCoursesQueryTotal;
	}

	newCourse(): void{
		this.$router.push({name: 'CourseCreate'})
	}

	goToCourse(courseId: string): void{
		this.$router.push({
			name: 'CourseDashboard',
			params:{ courseId },
		})
	}
	editCourse(courseId: string, step?: string): void{
		this.$router.push({
			name: 'EditCourse',
			params:{ courseId, currentStep: step },
		})
	}
}
