import { render, staticRenderFns } from "./AssignmentEvaluation.vue?vue&type=template&id=b5a44c16&"
import script from "./AssignmentEvaluation.vue?vue&type=script&lang=ts&"
export * from "./AssignmentEvaluation.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports