
import { Component, Vue } from 'vue-property-decorator';
import InstructorCourseDashboard from '../components/courses/InstructorCourseDashboard.vue'
import Page from '../views/Page.vue';

@Component({
	components: {
		Page,
		InstructorCourseDashboard
	}
})
export default class CourseDashboard extends Vue{

}
