
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiAccountMultipleOutline } from '@mdi/js';
import { VuetifyMixin } from '../../mixins';
import { CourseModel } from '../../models/course';
import CourseHeader from './CourseHeader.vue';

@Component({
	components: { CourseHeader }
})
export default class CourseCard extends Mixins(VuetifyMixin){
	mdiAccountMultipleOutline = mdiAccountMultipleOutline;
	@Prop() private color: string;
	@Prop({ required: true }) private course: CourseModel;

	get Color(){
		if(this.color) return this.color;
		return this.course.color;
	}
	get StudentCount(){
		return this.course.students.length;
	}
}
