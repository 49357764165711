
import { Component, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import {
	mdiPlay,
	mdiClipboardPlay,
	mdiCloseCircle,
	mdiClipboardTextOutline,
} from "@mdi/js";

import { filesize } from '../../pipes';
import { getMediumDateString, formatDurationShort } from '../../helpers/date';

import {
	clipCreationToolStore,
	videoEditorStore,
	coursesStore
} from "../../store";

import VideoEditorOne from "./VideoEditorOne.vue";
import { SourceVideoMuxModel } from '../../models/source-video/SourceVideoMuxModel';

import { MarkupTagClipMuxModel } from "../../models/markup-tag-clip-mux/MarkupTagClipMuxModel";
import RoundedButton from '../forms/RoundedButton.vue';
import AssignmentChip from '../ui/AssignmentChip.vue';
import { RubricModel } from '../../models/course';
import { AssignmentModel } from '../../models/course/AssignmentModel';
import VideoEvaluationClipDetailsForm from './VideoEvaluationClipDetailsForm.vue';
import { VideoOverlayLayoutMixin } from "../../mixins";

@Component({
	components: {
		RoundedButton,
		AssignmentChip,
		VideoEditorOne,
		VideoEvaluationClipDetailsForm
	}
})
export default class VideoEvaluationClipCreator extends Mixins(VideoOverlayLayoutMixin) {
	@Prop({ default: false, type: Boolean }) value: boolean;
	@Prop() selectedCourseId = '';
	@Prop() selectedCourseName = '';
	@Prop() selectedAssignment: AssignmentModel;
	@Prop() selectedStudentId = '';
	@Prop() selectedStudentName = '';
	@Prop() theRubricInUse: RubricModel;
	@Prop() initiallySelectedSourceVideoId = '';
	@Prop() initiallySelectedClipId = '';

	get AssignmentTitleHeight(): number{
		return 70;
	}
	get BelowPlayerHeight(): number{
		return 70;
	}
	get PlayerHeight(): number{
		return this.ContentHeight - this.AssignmentTitleHeight - this.HeaderHeight - this.BelowPlayerHeight;
	}
	get AssignmentTitleHeightStyle(): Record<'height',string>{
		return { 'height': `${this.AssignmentTitleHeight}px` };
	}
	get BelowPlayerHeightStyle(): Record<'height',string>{
		return { 'height': `${this.BelowPlayerHeight}px` };
	}
	get PlayerHeightStyle(): Record<'height',string>{
		return { 'height': `${this.PlayerHeight}px` };
	}
	get VideoContainerStyle(): Record<string, string>{
		if(this.$vuetify.breakpoint.mdAndDown){
			return {
				width: '100%',
			}
		}
		// This prevents the player controls from clipping at the bottom
		return {
			'width': '80%',
			'max-width': '900px',
		};
	}

	mdiPlay = mdiPlay;
	mdiCloseCircle = mdiCloseCircle;
	mdiClipboardPlay = mdiClipboardPlay;
	mdiClipboardTextOutline = mdiClipboardTextOutline;
	
	currentSourceVideo: SourceVideoMuxModel | null = null;

	selectedMarkupTagClipId: string = "";

	showingClipEntryForm = false;
	entryClipDetails: MarkupTagClipMuxModel = new MarkupTagClipMuxModel();

	@Watch('value')
	async valueChanged (new_val: boolean, old_val: boolean) {
		if (new_val === true) {
			// set up the editor to show a specific clip
			if (this.initiallySelectedSourceVideoId !== '') {
				await this.selectAVideoById(this.initiallySelectedSourceVideoId);
				await this.selectAMarkupTagClipById(this.initiallySelectedClipId);
			}
		}
	}

	get show() {
		return this.value;
	}

	get sourceVideosLoaded() {
		return clipCreationToolStore.sourceVideosLoaded;
	}

	// get currentSourceVideo() {
	//   console.log("!!!!! the current source video was changed ... why is this not called ??");
	//   if (videoEditorStore.loadedSourceVideo) {
	//     return videoEditorStore.loadedSourceVideo;
	//   } else {
	//     return null;
	//   }
	// }

	get allMarkupTagClipsForCurrentSV() {
		return videoEditorStore.markupTagClipsForSV;
	}

	get allMarkupTagClipsForCurrentSVByCriteriaStructure() {
		const resultStructure: any[] = [];
		this.theRubricInUse.criteriaGroup.forEach( tempCG => {
			const tempCGStructure: any = { name: tempCG.name, mtcs: [] }
			videoEditorStore.markupTagClipsForSV.forEach( tempMTC => {
				if (tempMTC.markType === tempCG.name) {
					tempCGStructure.mtcs.push(tempMTC);
				}
			});
			resultStructure.push(tempCGStructure);
		});
		return resultStructure;
	}

	get currentlySelectedMarkupTagClipID() {
		return videoEditorStore.selectedClipFromSourceVideoId;
	}

	get showCreateClipButton() {
		if (videoEditorStore.playerCurrentTime && videoEditorStore.playerCurrentTime>0.0) {
			return true;
		} else {
			return false;
		}
	}

	filesize(value: number): string {
		return filesize(value, 1)
	}

	datestring(theDate: Date): string {
		// console.log("How often does the DateString function get called");
		return getMediumDateString(theDate);
	}

	formatDurationShort(theDuration: number): string {
		return formatDurationShort(theDuration, true, 's');
	}

	formatStartTime(theStartTime: number): string {
		return 'at: ' + formatDurationShort(theStartTime, true, 's');
	}

	async created() {
		// await teamDashboardStore.loadTeam('95cf7117-c6e9-4bbd-a59b-9ca365623a33');
		// console.log("In the Created life cycle event of the ClipCreatorEvent page");
		await videoEditorStore.setupStore();
		// await clipCreationToolStore.loadSourceVideosFromDB({ emptyParam: 'hello' });
	}

	async selectAVideoById(sourceVideoId: string) {
		const svIndex = clipCreationToolStore.sourceVideosLoaded.findIndex(sv => sv.id === sourceVideoId);
		if (svIndex >= 0) {
			await this.selectedAVideo(clipCreationToolStore.sourceVideosLoaded[svIndex]);
		} else {
			console.log("selectAVideoById in VideoEvaluationClipCreator - the ID was not found");
			// show a toast to the user saying the video was not currenly loaded.
		}

	}

	async selectedAVideo(theSVSelected: SourceVideoMuxModel) {

		this.onResizeEditorPage();
		const getThis = await videoEditorStore.setSourceVideo(theSVSelected);

		this.currentSourceVideo = videoEditorStore.loadedSourceVideo;

		// console.log("What about the new markup clips ?? ");
		// console.log(this.allMarkupTagClipsForCurrentSV);

		// call back to the store to load the video clips for this source video.
		// const clipLoadResponse = await videoEditorStore.loadAllClipsForSourceVideo(theSVSelected.id)
	}

	async deleteTheVideo(theSV: SourceVideoMuxModel) {
		console.log("the user selected to delete the video with name: ", theSV.originalFileName);

		// implement ARE YOU SURE Dialog here.
	}

	async selectAMarkupTagClipById(idOfMTC: string) {
		videoEditorStore.updateClipSelectedToClipId(idOfMTC);
		this.selectedMarkupTagClipId = idOfMTC;
	}

	async selectedAMarkupTagClip(theMTC: MarkupTagClipMuxModel) {
		console.log(
			"User clicked on one of the markup clips - jumping to it: ",
			theMTC
		);
		videoEditorStore.gotClickOnATagClipImage(theMTC);
		this.selectedMarkupTagClipId = theMTC.id;
	}

	async deleteAMarkupTagClip(theMTC: MarkupTagClipMuxModel) {
		videoEditorStore.deleteAClip(theMTC)
		// implement ARE YOU SURE Dialog here.
	}

	private isNormalInteger(theValue: string) {
		var n = Math.floor(Number(theValue));
		return n !== Infinity && String(n) === theValue && n >= 0;
	}

	private nextSuggestedClipName(clip: MarkupTagClipMuxModel): string {
		const totalClips = videoEditorStore.markupTagClipsForSV.length + 1;
		const rootName = clip.tagKeywords.length === 0 ? 'Clip' : clip.tagKeywords.join(', ');
		return `${rootName} #${totalClips}`;
	}

	private setEntryClipStartingDetails() {
		const tempSV: SourceVideoMuxModel = videoEditorStore.loadedSourceVideo;
		videoEditorStore.manuallySplitCursorBasedOnDefaultValues();

		this.entryClipDetails.markStart = (Math.round(((videoEditorStore.startMarkerPosition / videoEditorStore.playerWidth) * videoEditorStore.totalTime) * 100)) / 100;

		this.entryClipDetails.markName = '';

		this.entryClipDetails.fromMuxSourceVideoId = tempSV.id;
		this.entryClipDetails.fromMuxVideoPlaybackId = tempSV.muxVideoPlaybackId;
		this.entryClipDetails.fromMuxVideoPlaybackUrl = tempSV.muxVideoPlaybackUrl;
		this.entryClipDetails.fromMuxVideoAssetId = tempSV.muxVideoAssetId;
		this.entryClipDetails.teamEventParentId = tempSV.teamEventParentId;
		this.entryClipDetails.teamParentId = tempSV.teamParentId;
		
		// this.entryClipDetails.markDuration = 4.0
		this.entryClipDetails.markDuration = (Math.round((((videoEditorStore.stopMarkerPosition - videoEditorStore.startMarkerPosition) / videoEditorStore.playerWidth) * videoEditorStore.totalTime) * 100)) / 100;

		this.entryClipDetails.screenShotUrl = 'https://image.mux.com/' + this.entryClipDetails.fromMuxVideoPlaybackId + '/thumbnail.png?time=' + this.entryClipDetails.markStart.toFixed(1).toString()


	}

	async createClipScreenTransition() {
		if (this.showingClipEntryForm) {
			this.showingClipEntryForm = false;
		} else {
			this.setEntryClipStartingDetails()
			this.showingClipEntryForm = true;
		}
		Vue.nextTick(() => {
			this.onResizeEditorPage();
		})
	}

	async clipDetailsEntryFormSubmitted(theEvent: MarkupTagClipMuxModel) {
		// console.log("In Clip Details Entry Form Submitted .. the event is: ", theEvent);
		// console.log("In Clip Details Entry Form Submitted .. the temp clip state is: ", this.entryClipDetails);
		
		theEvent.dateTagCreated = new Date()
		
		theEvent.markName = this.nextSuggestedClipName(theEvent);

		// console.log('The MarkupTagClip to be entered is: ', theEvent);
		const theAddedClip: MarkupTagClipMuxModel = await videoEditorStore.addMarkupTagClip(theEvent);
		// add it to the coursse in the course store.
		coursesStore.addUpdateClipInAssignment({
			theClip: theAddedClip,
			courseId: this.selectedCourseId,
			studentId: this.selectedStudentId,
			assignmentId: this.selectedAssignment.id
		});
 
		videoEditorStore.resetScrubberToWhole();
	}

	clipDetailsViewClosed() {
		this.entryClipDetails = new MarkupTagClipMuxModel();
		this.createClipScreenTransition();
	}

	async clipEntryCancelled(theEvent: any) {
		// console.log("In Clip Form CANCELLED .. the event is: ", theEvent);
		// console.log("In Clip Form CANCELLED .. the temp clip state is: ", this.entryClipDetails);
		this.entryClipDetails = new MarkupTagClipMuxModel();
		this.createClipScreenTransition();
	}

	viewTheRubric() {
		console.log("this viewTheRubric function is not implemented currently")
	}

	dragMove(e: MouseEvent) {
		// console.log('The drag move has occurred, and the event is: ', e)
		if (videoEditorStore.isDragging || videoEditorStore.isDraggingLeftMarker || videoEditorStore.isDraggingRightMarker) {
			videoEditorStore.dragMove(e);
		}
	}

	dragStop(e: MouseEvent, stopType: string) {
		// console.log('********************************************************')
		// console.log('The drag stop has occurred, and the event is: ', e)
		// console.log('the element id is: ', (e.srcElement as HTMLDivElement).id)
		// console.log('the Offset x is: ', e.offsetX)
		// console.log('the Offset left is: ', (e.srcElement as HTMLDivElement).offsetLeft)
		// console.log('The parent element is: ', ((e.srcElement as HTMLDivElement).offsetParent as HTMLDivElement).id)
		// console.log('The parent element ofset left is: ', ((e.srcElement as HTMLDivElement).offsetParent as HTMLDivElement).offsetLeft)
		// console.log('********************************************************')
		if (videoEditorStore.isDragging || videoEditorStore.isDraggingLeftMarker || videoEditorStore.isDraggingRightMarker) {
			if (stopType === "up") {
				videoEditorStore.dragStopOnMouseUp(e);
			} else {
				videoEditorStore.dragStopOnMouseLeave(e);
			}
		}
	}

	closeStudio() {
		// console.log("!!!****  Close Stud")
		videoEditorStore.pausePlaying();
		this.value = false;
		this.$emit('update:value', this.value);
	}

	onResizeEditorPage() {
		// console.log("Got the page resize event !! -  Set the offset.");
		try {
			videoEditorStore.setPlayerLeftOffset(
				(this.$refs.fullContent as HTMLDivElement).offsetLeft
			);
		} catch (error) {
			console.log("Error getting the left offset of the Video editor")
		}

	}

	async mounted() {
		// console.log("IN VideoClipCreator MOUNTED method ... ")
		this.$nextTick(() => {
			// console.log("In the nextTick of the ClipCreatorEditor mounted routine -- regestering the resize event listener");
			window.addEventListener("resize", this.onResizeEditorPage);
			this.onResizeEditorPage();
		});

		// this.theRubricInUse = this.RubricTemplates[0];
		// console.log("!!!! the rubric in use is: ", this.theRubricInUse);
		// console.log("!!!! the rubric templates are: ", this.RubricTemplates);
	}

	async beforeDestroy() {
		// console.log("IN VideoClipCreator BEFOREDESTROY method ... ")
		window.removeEventListener("resize", this.onResizeEditorPage);
		// leaving the page ... so remove the current teamUID ... but don't unload the data.
		// console.log("Does this get called on course switch ?? ");
		videoEditorStore.teardownVidStore();
		// clipCreationToolStore.teardownTeamEventStore();
	}
}
