
import { Component, Prop, Vue } from 'vue-property-decorator';
import { StudentModel, AssignmentModel } from '../../models/course';
import { AssignmentStatus } from '../../../types/enums';

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	}
})
export default class StudentInfoProvider extends Vue{
	@Prop({ default: 'div' }) private as: string;

	@Prop({ default: 'baColorSuperLightBlue' }) private student: StudentModel;

	get SlotProps(){
		return {
			coachProfileIsLinked: this.Student.coachProfileId && this.Student.coachProfileId.length > 0,
			student: this.Student,
			currentAssignment: this.CurrentAssignment,
			currentAssignmentName: this.CurrentAssignmentName,
			assignmentStatus: this.CurrentAssignmentStatus,
			incompleteAssignments: this.IncompleteAssignments,
			completedAssignments: this.CompletedAssignments,
		};
	}

	get Student(){
		return this.student;
	}
	get CurrentAssignment(): AssignmentModel | undefined{
		return this.IncompleteAssignments[0];
	}
	get IncompleteAssignments(): AssignmentModel[]{
		return this.student.assignments.filter(a => a.status !== AssignmentStatus.Completed);
	}
	get CompletedAssignments(): AssignmentModel[]{
		return this.student.assignments.filter(a => a.status === AssignmentStatus.Completed);
	}
	get CurrentAssignmentName(){
		if(this.CurrentAssignment === undefined) return "None";
		return this.CurrentAssignment.name;
	}
	get CurrentAssignmentStatus(){
		if(this.CurrentAssignment === undefined) return "No Assignment";
		return this.CurrentAssignment.status;
	}

}
