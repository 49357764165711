
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiPlus, mdiChevronDown, mdiCloseCircle } from '@mdi/js';

import { AvailableProgramTypes, FormRulesMixin } from '../../mixins';
import Alert from '../ui/Alert.vue';
import FormAddArea from '../forms/FormAddArea.vue';
import FileUploadArea from '../forms/FileUploadArea.vue';
import DatePicker from '../forms/DatePicker.vue';
import MonthPicker from '../forms/MonthPicker.vue';

@Component({
	components: { Alert, DatePicker, FormAddArea, FileUploadArea, MonthPicker },
})
export default class CourseCreateCourseInfo extends Mixins(AvailableProgramTypes, FormRulesMixin){
	mdiPlus = mdiPlus;
	mdiChevronDown = mdiChevronDown;
	mdiCloseCircle = mdiCloseCircle;

	thisYear: number = new Date().getFullYear();
	@Prop() value = {
		valid: false,
		students: [],
		sendStudentInvitations: true,
	};
	input(){
		this.$emit('input', this.value);
	}

	$refs:{
		form: HTMLFormElement;
	}

	csvError = "";
	uploadCSVLoading: boolean = false;
	/**
	 * This uploader will break if you upload a CSV with CRLF line endings. (Looking at you, windows users)
	 */
	uploadCSV(csv: File){
		this.uploadCSVLoading = true;
		this.csvError = "";
		if(csv.type !== 'text/csv'){
			console.error("File is not a valid CSV");
			this.csvError = "File is not a valid CSV";
			return;
		}
		const fileReader = new FileReader()
		fileReader.onload = ($event: any) => {
			const csvString = $event.target.result;
			const csvRoster = csvString.split(/\r?\n/).map(line => line.split(','));
			csvRoster.slice(1).forEach(([firstName, lastName, email]) => {
				this.value.students.push({ firstName, lastName, email });
			});
			this.uploadCSVLoading = false;
		}
		fileReader.readAsText(csv);
	}

	addStudent(){
		this.value.students.push({
			firstName: "",
			lastName: "",
			email: "",
		});
	}
	removeStudent(index: number){
		this.value.students.splice(index, 1);
	}

	async submit($event: Event) {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.value.valid === false) return;
		this.$emit('submit', this.value);
	}

}
