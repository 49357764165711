
import { Component, Mixins } from 'vue-property-decorator';

import Page from '@/views/Page.vue';
import { mdiVideoPlus } from '@mdi/js';
import AssignmentChip from '@/components/ui/AssignmentChip.vue';
import CircledAvatar from '@/components/ui/CircledAvatar.vue';
import Discussion from '@/components/courses/Discussion.vue';
import RubricForm from '@/components/courses/RubricForm.vue';
import VideoEvaluationClipCreator from '@/components/videoclips/VideoEvaluationClipCreator.vue';
import { AssignmentMixin, VuetifyMixin, MyCoachMixin } from '@/mixins';
import AttachmentsArea from '@/components/forms/AttachmentsArea.vue';
import SingleCommentTextArea from '@/components/courses/SingleCommentTextArea.vue';
import TextEditor from '@/components/courses/TextEditor.vue';
import ProfilePictureProvider from '@/components/hoc/ProfilePictureProvider.vue';
import { videoEditorStore, clipCreationToolStore, coursesStore } from '@/store';
import { formatDatePrettyLong } from '@/helpers/date';
import { AssignmentStatus } from '@/../types/enums';
import { Evaluation, DomainObjectChangeOptions } from '@/../types/interfaces';
import { MarkupTagClipMuxModel } from '@/models/markup-tag-clip-mux/MarkupTagClipMuxModel';
import { CommentCreatedEvents } from 'types/interfaces/CommentCreateMetadata';

@Component({
	components: {
		AssignmentChip,
		AttachmentsArea,
		CircledAvatar,
		Discussion,
		Page,
		SingleCommentTextArea,
		RubricForm,
		VideoEvaluationClipCreator,
		ProfilePictureProvider,
		TextEditor,
	}
})
export default class AssignmentEvaluation extends Mixins(AssignmentMixin, MyCoachMixin, VuetifyMixin){
	mdiVideoPlus = mdiVideoPlus;
	formatDatePrettyLong = formatDatePrettyLong;

	confirmAssignmentComplete: boolean = false;

	get FileUploadParentId(): string{
		return this.CurrentAssignmentId;
	}
	get FileUploadApiPrefix(): string{
		return `course/${this.CurrentCourseId}/student/${this.CurrentStudentId}/assignment/${this.CurrentAssignmentId}/files`;
	}

	get UseVideoReview(): boolean{
		if(!this.CurrentAssignment) return false;
		return this.CurrentAssignment.useVideoReview;
	}

	private formValid: boolean = false;
	
	public sourceVideoIdToShowOnStart = '';
	public videoClipIdToShowOnStart = '';
	
	public showFullScreenEditorDialog = false;

	private formEvaluation: Evaluation = {
		categoryGrades:[],
		finalOutcome: null,
	};

	get PageLoading(): boolean {
		return !coursesStore.myCoursesInitialized || coursesStore.myCoursesLoading;
	}

	get CommentSubmitParams(): CommentCreatedEvents & DomainObjectChangeOptions{
		return {
			notify: true,
			type: "Course:AssignmentEvaluation",
			payload:{
				courseId: this.CurrentCourseId,
				assignmentId: this.CurrentAssignmentId,
				studentId: this.CurrentStudent ? this.CurrentStudent.id : "",
				coachId: this.MyCoachProfileId,
			}
		}
	}
	
	async openTheEditorWindow(): Promise<void> {
		if (!this.showFullScreenEditorDialog) {
			// console.log("----------------------- open the video editor ---------------------------")
			// console.log("About to open the editor window ... clearing the videoEditorStore");
			// console.log("The current assignment id is :", this.CurrentAssignmentId);
			// console.log("the current course: ", this.CurrentCourse);
			await videoEditorStore.emptyTheEditor();
			await clipCreationToolStore.loadSourceVideosForEvaluationAssignment(this.CurrentAssignmentId);
			this.showFullScreenEditorDialog = true;
		}
	}
	
	async openTheEditorWindowToSpecificClip(clipDetails: MarkupTagClipMuxModel): Promise<void> {
		if (!this.showFullScreenEditorDialog) {
			// console.log("--------- open the video editor to specific clip -----------------")
			// console.log("About to open the editor window ... to a specific clip");
			// console.log("the clip to show is: ", clipDetails);
			await videoEditorStore.emptyTheEditor();
			await clipCreationToolStore.loadSourceVideosForEvaluationAssignment(this.CurrentAssignmentId);
			// console.log('The Cliep CreationToolStore ... should be set up !! ');
			// console.log("-------------------------------------------------------------------")
			this.sourceVideoIdToShowOnStart = clipDetails.fromMuxSourceVideoId;
			this.videoClipIdToShowOnStart = clipDetails.id;
			this.showFullScreenEditorDialog = true;
		}
	}

	private returnToTheDashboard() {
		this.$router.push({ name: 'CourseDashboard', params: { courseId: this.CurrentCourseId }});
	}

	returnFromFullScreenDialog(): void {
		console.log("returning from Full screen dialog !!!")
		this.showFullScreenEditorDialog = false;
		this.sourceVideoIdToShowOnStart = '';
		this.videoClipIdToShowOnStart = '';
	}

	handleUpdatedEvaluation(evaluationEvent: Evaluation): void {
		this.formEvaluation = evaluationEvent;
	} 

	async submit(): Promise<void>{
		// console.log("The updated AssignmentEvaluation", this.formEvaluation);
		const updatedAssignment = this.CurrentAssignment
		updatedAssignment.evaluation = this.formEvaluation;
		updatedAssignment.status = AssignmentStatus.Completed;
		// console.log("The full updated Assignment", updatedAssignment);
		await coursesStore.updateAssignmentForStudent({
			updatedAssignment: updatedAssignment,
			courseId: this.CurrentCourseId,
			studentId: this.CurrentStudentId
		});

		// go back to the dashboard.
		this.returnToTheDashboard();
	}

	cancel(): void {
		// May want to pop a dialog before the cancel when unsubmitted changes
		this.returnToTheDashboard();
	}

}
