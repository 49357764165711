
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { mdiChevronDown } from '@mdi/js';
import { VuetifyMixin } from '../../mixins';
import MiniAssignmentChip from '../ui/MiniAssignmentChip.vue';
import UserChip from '../ui/UserChip.vue';
import ProfilePictureProvider from '../hoc/ProfilePictureProvider.vue';
import StudentInfoProvider from './StudentInfoProvider.vue';
import { StudentModel, AssignmentModel } from '../../models/course';
import { AssignmentStatus } from '../../../types/enums';

@Component({
	components: { MiniAssignmentChip, UserChip, ProfilePictureProvider, StudentInfoProvider },
})
export default class CourseStudentList extends Mixins(VuetifyMixin){
	mdiChevronDown = mdiChevronDown;

	sendAssignmentReminder(assignment: AssignmentModel, student: StudentModel): void{
		this.$emit('assignment:reminder', { assignment, student });
	}

	@Prop() students: StudentModel[];

	headers: DataTableHeader<any>[] = [
		{
			text: 'Student',
			value: 'student',
		},
		{
			text: 'Assignment(s)',
			value: 'assignment',
		},
		{
			text: 'Status',
			value: 'status',
		},
	];

	expandedItems: any[] = [];
	toggleExpandRow(item, isExpanded, expand){
		if(isExpanded){
			this.expandedItems = [];
		}else{
			expand(item, !isExpanded);
		}
	}
	
	goToAssignmentEvaluation(theAssignment: AssignmentModel, theStudent: StudentModel) {
		// console.log("Want to traverse to the assignment ... and the assignment is: ");
		// console.log(theAssignment);
		// console.log(theStudent)
		// should this be done one level up ??
		if (theAssignment.status !== AssignmentStatus.NotStarted) {
			const theCourseId = this.$route.params.courseId
			this.$router.push({ name: 'AssignmentEvaluation', params: { courseId: theCourseId, studentId: theStudent.coachProfileId, assignmentId: theAssignment.id }})
		}
	}
}
